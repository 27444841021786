import draggable from 'vuedraggable'
import { COMMUNICATION_TYPE, ACTION_TYPE_LIST } from '../../../../misc/commons'
import supplierRecallService from '../../../../services/supplier/supplier-recall'
import store from '../../../../store'
import noti from '../../../../utils/noti_toast'
import _ from 'lodash'
import Vue from 'vue'
import tinymce from 'vue-tinymce-editor'
import templateActionService from '../../../../services/supplier/template-action'
Vue.component('tinymce', tinymce)
export default {
    props: ['communicationId', 'actionData', 'recallDisabled', 'infoData', 'communicationName', 'communicationType'],
    components: {
        draggable,
    },
    data() {
        return {
            actions: [],
            description: '',
            updateStep0: '',
            recall: COMMUNICATION_TYPE.RECALL,
            productDisruption: COMMUNICATION_TYPE.PRODUCTDISRUPTION,
            actionType: null,
            isCustomizedContent: false,
            options: ACTION_TYPE_LIST,
            userAddedActionOptions: [],
            combineActionOption: [],
            isDragging: false,
            delayedDragging: false,
            info: {},
            title: 'Enter all of the actions that the provider needs to complete to satisfy the recall. The response type field tells the provider whether they simply need to respond that the action is complete or if they need to upload supporting images or documents.',
            isCustom: false,
            isMultiselect: false,
            isPredefined: true,
            isAllowOnlyOne: false,
            inputs: [{ value: '' }],
            value1: null,
            value2: null,
            isShow: true,
            orgType: store.state.userType,
        }

    },

    mounted() {
        this.info = this.infoData
        if (this.communicationType == this.productDisruption) {
            this.title = 'Enter all of the actions that the provider needs to complete to satisfy the supply advisory. The response type field tells the provider whether they simply need to respond that the action is complete or if they need to upload supporting images or documents.'
        }
        if (this.orgType == 'SUPPLIER') {
            this.actions = _.orderBy(this.actionData, ['sequence_number'], ['asc']).map((x, index) => {
                return {
                    ...x,
                    order: index + 1,
                    IsEditable: false,
                    IsDelete: false,
                }
            })
            if (this.info.status == 'OPEN') {
                this.isShow = false
            }
        }
        if (this.orgType == 'PROVIDER') {
            let index = this.options.findIndex(x => x.value == 'MultipleChoice')
            if (index > -1) {
                this.options.pop()
            }
            this.actions = _.orderBy(this.actionData, ['sequence_number'], ['asc']).map((x, index) => {
                return {
                    ...x,
                    order: index + 1
                }
            })
        }
    },
    created() {
        if (this.orgType == 'SUPPLIER') {
            templateActionService.getRecallMetaData(this.communicationType).then((res) => {
                this.userAddedActionOptions.push({ value: 'Custom action', type: null })
                res.data.d.forEach(element => {
                    this.userAddedActionOptions.push({ value: element.value, type: element.type, actionvalue: element.actionvalue, isAllowOnlyOne: element.isAllowOnlyOne })
                })
                if ((this.actionData === null || this.actionData === undefined || this.actionData.length === 0)) {
                    let actions = this.communicationType == this.recall ? res.data.d.filter(x => x.organizationId == 0) : res.data.d.filter(x => x.organizationId != 0)
                    for (let index = 0; index < actions.length; index++) {
                        actions[index].actionvalue = JSON.parse(actions[index].actionvalue)?.map((x, index) => {
                            return {
                                value: x.Value
                            }
                        })
                    }
                    this.actions = _.orderBy(actions, ['sequence_number'], ['asc']).map((x, index) => {
                        return {
                            action_type: x.type,
                            isAllowOnlyOne: x.isAllowOnlyOne,
                            actionValues: x.actionvalue,
                            description: x.value,
                            order: index + 1,
                            IsEditable: false,
                            IsDelete: false,
                            isCustomActionType: x.type === 'MultipleChoice' ? true : false
                        }
                    })
                }
            })
        }
    },
    computed: {
        dragOptions() {
            return {
                animation: 0,
                group: 'description',
                ghostClass: 'ghost',
                disabled: this.recallDisabled
            }
        },
        nextOrder() {
            if (this.actions && this.actions.length > 0) {
                return Math.max.apply(Math, this.actions.map(function (o) { return o.order })) + 1
            } else {
                return 1
            }
        }
    },
    watch: {
        isDragging(newValue) {
            if (newValue) {
                this.delayedDragging = true
                return
            }
            this.$nextTick(() => {
                this.delayedDragging = false
            })
        },
        'actionType'(val) {

            if (val) {
                this.isMultiselect = false
                if (val === 'MultipleChoice') {
                    this.isMultiselect = true
                }
            }
        },
        'actionValues'(val) {
            if (val) {
                this.isMultiselect = false
                if (val === 'MultipleChoice') {
                    this.isMultiselect = true
                }
            }
        },

    },
    methods: {
        change(item) {
            if(item.id>0){
                this.save()
            } else
            {
                if ( item.description == '') {
                    noti.error('Error', 'Please enter description.')
                }
                var action = this.actions.filter(x => x.description === item.description && x.order !== item.order  )
                if (action.length > 0) {
                    noti.error('Error', 'You have already selected the action.')
                    item.description=''
                }
                return item.IsEditable = false
            }
        },
        focusoutadd(value1) {
            this.add(value1)
        },

        addAction() {
            if (this.orgType == 'SUPPLIER') {
                let valid = true
                this.$refs['observerAddAction'].validate().then(isValid => {
                    if (isValid) {
                        var action = this.actions.filter(x => x.description === this.description)
                        if (action.length > 0) {
                            noti.error('Error', 'You have already selected the action.')

                        } else {
                            let Arr = []
                            if (this.actionType == 'MultipleChoice') {
                                let DupCkList = []
                                if (this.value1 != '' && this.value1 != null) {
                                    DupCkList.push(this.value1)
                                }
                                for (let index = 0; index < this.inputs.length; ++index) {
                                    if (DupCkList.includes(this.inputs[index].value)) {
                                        if (this.inputs[index].value == this.value1) {
                                            this.value1 = null
                                        }
                                        valid = false
                                        noti.error('Error', 'Duplicate values cannot be entered.')
                                        break
                                    }
                                    if (this.inputs[index].value != '') {
                                        DupCkList.push(this.inputs[index].value)
                                    }
                                }
                                if (!valid) {
                                    return ''
                                }
                                if (this.inputs.length > 1) {
                                    if (this.value1 != '' || this.value1 != null) {
                                        this.inputs.push({ value: this.value1 })
                                        this.value1 = null
                                    }
                                }
                                else {
                                    if (this.value1 != '' || this.value1 != null) {
                                        this.inputs.push({ value: this.value1 })
                                        this.value1 = null
                                    }
                                }
                                for (let index = 0; index < this.inputs.length; ++index) {
                                    if (this.inputs.length == 1 && this.inputs[index].value == '') {
                                        if (this.value1 == '' || this.value1 == null) {
                                            valid = false
                                            noti.error('Error', 'Please add at least one option.')
                                        }
                                        else {
                                            this.inputs.push({ value: this.value1 })
                                            this.value1 = null
                                        }
                                    }
                                    if (this.inputs[index].value != '' && this.inputs[index].value != null) {
                                        Arr.push(this.inputs[index])
                                    }
                                }
                            }
                            if (this.actionType == 'MultipleChoice' && Arr.length == 0) {
                                this.inputs = []
                                this.inputs.push({ value: '' })
                                if (valid) {
                                    valid = false
                                    noti.error('Error', 'Please add at least one option.')
                                }
                            }
                            if (valid) {
                                this.actions.push({
                                    id: null,
                                    description: this.description,
                                    action_type: this.actionType,
                                    isCustomizedContent: this.isCustomizedContent,
                                    order: this.nextOrder,
                                    isCustomActionType: (this.isCustom == true || this.communicationType == this.productDisruption) ? true : false,
                                    isMultiselect: this.isMultiselect,
                                    isAllowOnlyOne: this.isAllowOnlyOne,
                                    actionValues: this.actionType == 'MultipleChoice' ? Arr : this.inputs,
                                    IsEditable: false,
                                    IsDelete: true,
                                })
                            }
                        }
                        if (valid) {
                            this.description = ''
                            this.actionType = null
                            this.isCustom = false
                            this.isAllowOnlyOne = false
                            this.isMultiselect = false
                            this.isCustomizedContent = false
                            this.inputs = []
                            this.inputs.push({ value: '' })
                            this.isPredefined = true
                            requestAnimationFrame(() => {
                                this.$refs.observerAddAction.reset()
                            })
                        }
                    }
                })
            }
            if (this.orgType == 'PROVIDER') {
                this.$refs['observerAddAction'].validate().then(isValid => {
                    if (isValid) {
                        var action = this.actions.filter(x => x.description === this.description)
                        if (action.length > 0) {
                            noti.error('Error', 'You have already selected the action.')

                        } else {
                            this.actions.push({
                                id: null,
                                description: this.description,
                                action_type: this.actionType,
                                order: this.nextOrder
                            })
                        }
                        this.description = ''
                        this.actionType = null
                        requestAnimationFrame(() => {
                            this.$refs.observerAddAction.reset()
                        })
                    }
                })
            }
        },
        removeDescriptionAction(item) {
            this.isCustom = false
            this.isPredefined = true
            this.actionType = null
            this.inputs = [0]
            this.inputs.push[{ value: null }]
            this.value1 = null
        },
        removeAction(item) {
            if (this.actions && this.actions.length > 0) {
                const _index = this.actions.findIndex(x => x.order === item.order)
                if (_index !== -1) {
                    this.actions.splice(_index, 1)
                }
            }
        },

        EditAction(item, status, Isedit) {
            let isError = false
            for (let i = 0; i < this.actions.length; ++i) {
                if (this.actions[i].IsEditable) {
                    if (this.actions[i].action_type == 'MultipleChoice') {
                        let isValid = true
                        let vakl = this.value2
                        this.actions[i].actionValues.filter(function (elem) {
                            if (elem.value == vakl) {
                                isValid = false
                            }
                        })
                        if (vakl == null || vakl == '' || vakl == undefined) {
                            isValid = true
                        }

                        let DupCkList = []
                        if (this.value2 != null && this.value2 != undefined && this.value2 != '') {
                            DupCkList.push(this.value2)
                        }
                        for (let index = 0; index < this.actions[i].actionValues.length; ++index) {
                            if (DupCkList.includes(this.actions[i].actionValues[index].value)) {
                                if (this.actions[i].actionValues[index].value == this.value2) {
                                    this.value2 = null
                                }
                                isValid = false
                                break
                            }
                            if (this.actions[i].actionValues[index].value != '') {
                                DupCkList.push(this.actions[i].actionValues[index].value)
                            }
                        }
                        if (!isValid) {
                            noti.error('Error', 'Duplicate values cannot be entered.')
                            return ''
                        }
                        if (this.actions[i].actionValues.length == 1 && this.actions[i].actionValues[0].value == '' && (this.value2 == null || this.value2 == undefined || this.value2 == '') && Isedit != undefined) {
                            isError = true
                            this.actions[i].actionValues = []
                            this.actions[i].actionValues.push({ value: '' })
                            noti.error('Error', 'Please add at least one option.')
                            break
                        }
                        let isValError = true
                        let Arr = []
                        for (let y = 0; y < this.actions[i].actionValues.length; ++y) {
                            if (this.actions[i].actionValues[y].value != '' && this.actions[i].actionValues[y].value != null || Isedit == undefined) {
                                if (this.value2 != null && this.value2 != undefined && this.value2 != '') {
                                    isValError = true
                                }
                                else {
                                    isValError = false
                                }
                            }
                        }
                        for (let index = 0; index < this.actions[i].actionValues.length; ++index) {
                            if (this.actions[i].actionValues[index].value != '' && this.actions[i].actionValues[index].value != null) {
                                Arr.push(this.actions[i].actionValues[index])
                            }
                        }
                        if (Arr.length == 0) {
                            if (Isedit != undefined) {
                                if (this.value2 != null && this.value2 != undefined && this.value2 != '') {
                                    this.actions[i].actionValues = []
                                    this.actions[i].actionValues.push({ value: this.value2 })
                                }
                                else {
                                    isError = true
                                    noti.error('Error', 'Please add at least one option.')
                                    this.actions[i].actionValues = []
                                    this.actions[i].actionValues.push({ value: null })
                                    break
                                }
                            }
                            Arr.push({ value: null })
                        }
                        this.actions[i].actionValues = []
                        this.actions[i].actionValues = Arr
                        if (this.value2 != null && this.value2 != undefined && this.value2 != '') {
                            isValError = false
                        }
                        if (isValError) {
                            if (!isError) {
                                this.actions[i].actionValues = []
                                this.actions[i].actionValues.push({ value: '' })
                                isError = true
                                noti.error('Error', 'Please add at least one option.')
                                break
                            }
                        }
                    }
                    if (this.actions[i].action_type == 'MultipleChoice' && this.value2 != null && this.value2 != undefined && this.value2 != '') {
                        this.actions[i].actionValues.push({ text: null, value: this.value2 })
                        this.value2 = null
                    }
                }
                if (!isError) {
                    this.actions[i].IsEditable = false
                }
            }
            if (!isError) {
                if (item.action_type == 'MultipleChoice') {
                    if (this.value2 != '' && this.value2 != null) {
                        item.actionValues.push({ value: this.value2 })
                        this.value2 = null
                    }
                    if (item.actionValues.length == 0) {
                        item.actionValues.push({ value: '' })
                    }
                }
                else {
                    item.actionValues = []
                    item.actionValues.push({ value: '' })
                }
                if (item.action_type != 'MultipleChoice') {
                    if (item.description == '') {
                        item.IsEditable = true
                    }
                    else {
                        item.IsEditable = status
                    }
                }
                else {
                    item.IsEditable = Isedit == undefined ? true : Isedit
                }
            }
        },

        getDescription() {
            if (this.orgType == 'SUPPLIER') {
                templateActionService.getRecallMetaData(this.communicationType).then((res) => {
                    this.description = res.data
                })
            }
        },
        AddEditAction(item, status) {
            let isValid = true
            let vakl = this.value2
            item.filter(function (elem) {
                if (elem.value == vakl) {
                    isValid = false
                }
            })
            if (isValid) {
                if (this.value2 != '' && this.value2 != null) {
                    item.push({ value: this.value2 })
                    this.value2 = null
                }
            }
            else {
                if (this.value2 == null) {
                    return ''
                }
                else {
                    this.value2 = null
                    noti.error('Error', 'Duplicate values cannot be entered.')
                    return ''
                }

            }
        },
        onMove({ relatedContext, draggedContext }) {
            const relatedElement = relatedContext.element
            const draggedElement = draggedContext.element
            return (
                (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
            )
        },
        save(type) {
            if (this.orgType == 'SUPPLIER') {
                let isError = false
                for (let i = 0; i < this.actions.length; ++i) {
                    if (this.actions[i].IsEditable) {
                        if (this.actions[i].action_type == 'MultipleChoice') {
                            let Arr = []
                            let DupCkList = []
                            if (this.value2 != null && this.value2 != undefined && this.value2 != '') {
                                DupCkList.push(this.value2)
                            }
                            for (let index = 0; index < this.actions[i].actionValues.length; ++index) {
                                if (this.actions[i].actionValues[index].value != '' && this.actions[i].actionValues[index].value != null) {
                                    Arr.push(this.actions[i].actionValues[index])
                                }
                                if (DupCkList.includes(this.actions[i].actionValues[index].value)) {
                                    if (this.actions[i].actionValues[index].value == this.value2) {
                                        this.value2 = null
                                    }
                                    isError = true
                                    noti.error('Error', 'Duplicate values cannot be entered.')
                                }
                                if (this.actions[i].actionValues[index].value != '') {
                                    DupCkList.push(this.actions[i].actionValues[index].value)
                                }
                            }
                            this.actions[i].actionValues = []
                            this.actions[i].actionValues = Arr
                            if (isError) {
                                return ''
                            }
                        }
                        if (this.actions[i].action_type == 'MultipleChoice' && this.actions[i].actionValues.length == 1 && this.actions[i].actionValues[0].value == '' && (this.value2 == null || this.value2 == undefined || this.value2 == '')) {
                            isError = true
                            noti.error('Error', 'Please add at least one option.')
                            break
                        }
                        if (this.actions[i].action_type == 'MultipleChoice' && this.value2 != null && this.value2 != undefined) {

                            let isValid = true
                            let vakl = this.value2
                            this.actions[i].actionValues.filter(function (elem) {
                                if (vakl != '' && elem.value == vakl) {
                                    isValid = false
                                }
                            })
                            if (isValid) {
                                if (this.value2 != '' && this.value2 != null) {
                                    this.actions[i].actionValues.push({ text: null, value: this.value2 })
                                    this.value2 = null
                                }
                                else {
                                    let Arr = []
                                    for (let index = 0; index < this.actions[i].actionValues.length; ++index) {
                                        if (this.actions[i].actionValues[index].value != '' && this.actions[i].actionValues[index].value != null) {
                                            Arr.push(this.actions[i].actionValues[index])
                                        }
                                    }
                                    if (Arr.length == 0) {
                                        isError = true
                                        Arr.push({ value: null })
                                        this.actions[i].actionValues.push({ value: null })
                                        noti.error('Error', 'Please add at least one option.')
                                        break
                                    }
                                    this.actions[i].actionValues = []
                                    this.actions[i].actionValues = Arr
                                }
                            }
                            else {
                                isError = true
                                this.value2 = null
                                noti.error('Error', 'Duplicate values cannot be entered.')
                                break
                            }
                        }
                        let isValError = true
                        for (let y = 0; y < this.actions[i].actionValues.length; ++y) {
                            if (this.actions[i].actionValues[y].value != '' && this.actions[i].actionValues[y].value != null) {
                                isValError = false
                            }
                        }
                        if (this.value2 != null && this.value2 != undefined) {
                            isValError = false
                        }
                        if (this.actions[i].action_type != 'MultipleChoice') {
                            isError = false
                            isValError = false
                        }
                        if (isValError) {
                            if (!isError) {
                                this.actions[i].actionValues = []
                                this.actions[i].actionValues.push({ value: null })
                                isError = true
                                noti.error('Error', 'Please add at least one option.')
                                break
                            }
                        }
                    }
                    if (!isError) {
                        this.actions[i].IsEditable = false
                    }
                }
                let isDescriptionError = false
                for (let i = 0; i < this.actions.length; ++i) {
                    if (this.actions[i].description == '') {
                        isDescriptionError = true
                        noti.error('Error', 'Please enter description.')
                        break
                    }
                }
                if (isError || isDescriptionError) {
                    return
                }
            }
            return new Promise((resolve, reject) => {
                if (this.actions && this.actions.length > 0) {
                    this.$refs['observerListAction'].validate().then(isValid => {
                        if (isValid) {
                            supplierRecallService.saveCommunicationStep2Actions({
                                id: this.communicationId,
                                communicationType: this.communicationType,
                                actions: this.actions,
                                clicktype: type
                            }).then(res => {
                                if (!res.error) {
                                    resolve(res.data.d)
                                }
                            })
                        } else {
                            reject('invalid step 2')
                        }
                    })
                } else {
                    supplierRecallService.saveCommunicationStep2Actions({
                        id: this.communicationId,
                        communicationType: this.communicationType,
                        actions: this.actions,
                        clicktype: type
                    }).then(res => {
                        if (!res.error) {
                            resolve(res.data.d)
                        }
                    })
                }
            })
        },
        input(event) {
            if (event) {
                this.isCustom = false
                if (event === 'Custom action') {
                    this.description = ''
                    this.actionType = null
                } else {
                    var predefinedAction = this.userAddedActionOptions.filter(x => x.value === event)
                    if (predefinedAction) {
                        this.actionType = predefinedAction[0].type
                        var obj = eval('(' + predefinedAction[0].actionvalue + ')')
                        for (var i in obj)
                            this.inputs.push({ value: obj[i].Value })
                        this.isAllowOnlyOne = predefinedAction[0].isAllowOnlyOne
                    }
                }
                this.isCustom = true
                this.isPredefined = false


            } else {
                this.actionType = null
                this.inputs = [0]

            }
        },
        add(index) {
            let isValid = true
            let vakl = this.value1
            this.inputs.filter(function (elem) {
                if (elem.value == vakl) {
                    isValid = false
                }
            })
            if (isValid) {
                if (this.value1 != '' && this.value1 != null) {
                    this.inputs.push({ value: this.value1 })
                    this.value1 = null
                }
            }
            else {
                if (this.value1 == null) {
                    return ''
                }
                else {
                    this.value1 = null
                    noti.error('Error', 'Duplicate values cannot be entered.')
                    return ''
                }

            }
        },
        remove(index) {
            this.inputs.splice(index, 1)
        },
        removeEdit(item, index, data) {
            item.splice(index, 1)
            if (item.length == 0) {
                data.actionValues.push({ value: null })
            }
        },
    }
}